.dropdownItem:hover {
        background-color: #0404041A;
}

.profileName {
        font-weight: bold;
        /*font-size: 1.25em;*/
        color: black;
}

.providerProfileName {
        font-weight: bold;
        font-size: 1.1em;
        color: black;
        background-color: transparent;
        border-style: none;
}

.providerProfileName:hover {
        font-weight: bold;
        font-size: 1.1em;
        color: #EC1F35;
        background-color: transparent;
        border-style: none;
}

.providerProfileName:focus {
        font-weight: bold;
        font-size: 1.1em;
        color: #EC1F35;
        background-color: transparent;
        border-style: none;
}

.providerProfileName:active {
        font-weight: bold;
        font-size: 1.1em;
        color: #EC1F35;
        background-color: transparent;
        border-style: none;
}
