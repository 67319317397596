.mobileSearchInput {
        border-radius: 0.8em;
        text-indent: 1.5em;
        border: 0.2em solid white;
        background-color: transparent;
        color: white;
}

.searchInput {
        min-width: 300px;
        border-radius: 0.8em;
        text-indent: 1.5em;
        border: 0.2em solid white;
        background-color: transparent;
        color: white;
}

.searchInput::placeholder {
        /*color: white !important;*/
        color: #FFFFFFBF !important;
}

.dropdownItemFirstItem:hover {
        background-color: darkgray;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
}

.dropdownItemMiddleItem:hover {
        background-color: darkgray;
}

.dropdownItemLastItem:hover {
        background-color: darkgray;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
}
