.dialogContainer {
    /*background-color: rgba(0, 0, 0, 0.5);*/
    max-width: 95vw;
    /*margin: 0px;*/
    /*max-width: 90%;*/
    width: 500px;
}

.container {
    /*background-color: rgba(0, 0, 0, 0.5);*/
    display: flex;
    align-items: center;
    height: 450px;
    /*width: 100%;*/
    /*max-width: 90%;*/
    /*width: 300px;*/
}

.loginBox {
    /*width: 300px;*/
    margin-bottom: 30px;
}

